import React from "react";
import Layout from "../components/layout";
import "./index.scss";
import Fv from "../components/fv";
import BtnVm from "../components/btn-vm";
import Card from "../components/card";
import Flow from "../components/flow";
import ImgSec03 from "../images/index-sec-03.jpg";
import ImgSec04 from "../images/index-sec-04.jpg";
import ImgSec05 from "../images/index-sec-05.jpg";

const IndexPage = () => {
  return (
    <Layout>
      <Fv />

      {/* about */}
      <section className="about">
        <div className="container">
          {/* txt */}
          <div className="wrap">
            <div className="wrap-heading" data-color="primary">
              <span className="wrap-heading-top">About</span>
              <span className="wrap-heading-bottom">DLAPPについて</span>
            </div>
            <div className="wrap-inner">
              サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
            </div>
            <BtnVm href="/sub" txt="DLAPPについて詳しく知る" data="primary" />
          </div>
          {/* img */}
          <div className="wrap" data-type="img"></div>
        </div>
      </section>

      {/* feature */}
      <section className="feature">
        <div className="container">
          {/* img */}
          <div className="wrap" data-type="img"></div>
          {/* txt */}
          <div className="wrap">
            <div className="wrap-heading" data-color="secondary">
              <span className="wrap-heading-top">Feature</span>
              <span className="wrap-heading-bottom">DLAPPの特徴</span>
            </div>
            <div className="wrap-inner">
              サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
            </div>
            <BtnVm href="/sub" txt="DLAPPの特徴を見る" data="secondary" />
          </div>
        </div>
      </section>

      {/* voice */}
      <section className="voice">
        <div className="container">
          <div className="wrap">
            <div className="wrap-heading" data-color="primary">
              <span className="wrap-heading-top">Voice</span>
              <span className="wrap-heading-bottom">ご利用企業様の声</span>
            </div>
            <div className="wrap-inner">
              <Card
                data="1"
                img={ImgSec03}
                headingTop="XXXX株式会社"
                headingBottom="XX事業部"
                txt="サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
              />
              <Card
                data="2"
                img={ImgSec04}
                headingTop="XXXX株式会社"
                headingBottom="XX事業部"
                txt="サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
              />
              <Card
                data="3"
                img={ImgSec05}
                headingTop="XXXX株式会社"
                headingBottom="XX事業部"
                txt="サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
              />
            </div>
            <BtnVm href="/sub" txt="導入事例を見る" data="primary" />
          </div>
        </div>
      </section>

      {/* flow */}
      <section className="flow">
        <div className="container">
          <div className="wrap">
            <div className="wrap-heading" data-color="primary">
              <span className="wrap-heading-top">Flow</span>
              <span className="wrap-heading-bottom">導入までの流れ</span>
            </div>
            <div className="wrap-inner">
              <Flow
                data="1"
                number="1"
                icon="find_in_page"
                heading="DLAPPの使い方を知る"
                lead="サンプルテキストサンプルテキストサンプルテキスト"
                href="/sub"
                linktxt="使い方を知る"
              />
              <Flow
                data="2"
                number="2"
                icon="person_add"
                heading="アカウント登録"
                lead="サンプルテキストサンプルテキストサンプルテキスト"
                href="/sub"
                linktxt="アカウント登録"
              />
              <Flow
                data="3"
                number="3"
                icon="get_app"
                heading="ダウンロードする"
                lead="サンプルテキストサンプルテキストサンプルテキスト"
                href="/sub"
                linktxt="ダウンロード"
              />
              <Flow
                data="4"
                number="4"
                icon="phonelink_setup"
                heading="初期設定をする"
                lead="サンプルテキストサンプルテキストサンプルテキスト"
                href="/sub"
                linktxt="初期設定の方法"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
