import React from "react";
import { Link } from "gatsby";
import "./btn-vm.scss";

const BtnVm = (props) => {
  return (
    <Link to={props.href} className="btn-vm" data-color={props.data}>
      <i className="material-icons">navigate_next</i>
      <span>{props.txt}</span>
    </Link>
  );
};

export default BtnVm;
