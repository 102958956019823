import React from "react";
import "./flow.scss";
import { Link } from "gatsby";

const Flow = (props) => {
  return (
    <div className="flow-card" data-type={props.data}>
      <div className="flow-card-number">
        <span>{props.number}</span>
      </div>
      <div className="flow-card-icon">
        <span className="material-icons">{props.icon}</span>
      </div>
      <div className="flow-card-heading">
        <span>{props.heading}</span>
      </div>
      <div className="flow-card-inner">
        <p>{props.lead}</p>
        <Link to={props.href}>{props.linktxt}</Link>
      </div>
    </div>
  );
};

export default Flow;
