import React from "react";
import "./card.scss";

const Card = (props) => {
  return (
    <div className="card" data-type={props.data}>
      <div className="card-img">
        <img src={props.img} alt="card-img" />
      </div>
      <div className="card-heading">
        <span>{props.headingTop}</span>
        <span>{props.headingBottom}</span>
      </div>
      <div className="card-lead">
        <p>{props.txt}</p>
      </div>
    </div>
  );
};

export default Card;
